.modal-custom {
    .modal-content {
        min-width: 600px;
        select,
        input:focus {
            -webkit-box-shadow: none;
            border-bottom: 2px solid #003481;
        }
        input:disabled {
            border-radius: 10px;
            border-bottom: none;
            padding-left: 10px;

          }
    }
    .modal-title {
        color: red;
        width: 100%;
        text-align: center;
    }

    .modal-footer {
        button {
            margin: 0 2px;
        }
    }

    .form-label {
        font-size: 14px
    }
    input,
    input::placeholder {
        font-size: 14px;
    }

    .invalid-feedback {
        margin-top: 0px;
        font-size: 12px;
    }

    .row {
        // max-height: 3rem;
        .muitiple-row {
            max-height: none !important;
        }
    }

    
}

.modal-confirm {
    .modal-header {
        width: 100%;
        justify-content: center;
        border: none;
        .modal-title {
            width: 100%;
            text-align: center;
        }
    }
    .modal-footer {
        border: none;
        padding-top: 2px;
    }
}

.modal-cars {
    .modal-content {
        // min-height: 450px;
    }
}
.wrapper {   
    overflow: auto;   
    border-radius: 6px;   
    border: 1px solid #dee2e6; 
}
.table {   
    border-spacing: 0;   
    border-collapse: collapse;   
    border-style: hidden;   
    width:100%;   
    max-width: 100%;   
    margin-bottom: 0; 
}
.table-fixed thead {     
    background-color: #f5f5f5; 
}
th, td{   
    padding: 10px; 
}


.text-error {
    color: red;
    font-size: 12px;
}

.image-upload {
    width: 96px;
    height: 96px;
    margin: 0px 0px;
    padding: 0px;
    margin: 0 4px;
    cursor: pointer;
}
.hide-item {
    display: none ;
}
.btn-upload {
    border: 1px dashed #D9D9D9;
    border-radius: 5px;
    width: 96px;
    height: 96px;
    text-align: center;
    cursor: pointer;
    .icon {
        margin-top: 32px;
    }
}

.modal-noti {
    .modal-content {
        padding: 15px;
        border-radius: 20px;
    }
}

.table-fixed {
    min-height: 230px;
}