#page-dashboard{
    padding-top: 10px;
}


.page-main-content {
    margin-top: 56px;
    background-color: white;
}

.title-page-content {
    margin: 2px 20px 2px 5px ;
    display: flex;
    align-items: center;
    p {
        font-size: 20px;
        font-weight: 600;
    }
}
.div-step {
    display: flex;
    justify-content: flex-end;
    width: 80%;
}

.input-no-border {
    border-left: none;
}

.input-no-border-1 {
    border-right: none;
}
.hover-row :hover{
        background-color: #f5f5f5 !important;

}

.input-no-border-2 {
    border-left: none;
    border-right: none;

}

.pointer-btn {
    cursor: pointer;
    margin: 0 2px;
}
.btn-cur {
    cursor: pointer;
    
}
.btn-inline {
    width: max-content;
}

.btn-status-order {
    min-width: 120px;
    height:30px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-style: normal;
    gap: 4px !important;
    border-radius: 18px !important;
    // border: none !important;
    padding: 2px !important;
    
}
.title-div {
    color: #1976D2;
    margin-bottom: 4px;
}
.item-license {
    
    font-size: 16px;
    font-weight: 600;
}
.table-custom {
    .card-body {
        padding: 0px;
    }
    thead tr {
        background-color: #f5f5f5;
    }
    th {
        // color: red;
        font-size: 12px;
    }
    td {
        font-size: 12px;
    }

    .img-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #dbdbdb;
        border: 1px solid #dbdbdb;
    }
    .css-ql19fo-MuiDataGrid-overlayWrapper {
        position: static;
    }
}

.table-product {
    .card-body {
        padding: 0px;
    }
    thead tr {
        background-color: #f5f5f5;
    }
    th {
        // color: red;
        font-size: 14px;
    }
    td {
        font-size: 14px;
    }

    .img-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .css-ql19fo-MuiDataGrid-overlayWrapper {
        position: static;
    }
}

.div-date-search {
    // display: flex;
    .form-label {
        white-space: nowrap;
        margin-right: 3px;
        margin-top: 5px;
    }
    .react-datepicker-wrapper {
        margin: 0px 3px;
        // width: 100%;
    }
}
.title-page-list {
    margin: 4px 0;
    font-size: 24px;
    display: flex;
    font-weight: 700;
    line-height: 32px;
    justify-content: space-between;
}
.form-search {
    // .react-datepicker__month-container {
    //     z-index: 9999;

    // }
    .input-group-text,
    .form-control {
        // border: none ;
        // border-radius: 0;
        // border-bottom: 1px solid ;
        // padding-left: 4px;
        
    }
    .form-select {
        // border: none ;
        // border-radius: 0;
        // border-bottom: 1px solid ;
        // padding-left: 4px;

    }
    input,
    input::placeholder {
        font-size: 14px;
    }
    select,
    input::placeholder {
        font-size: 14px;
    }

    input:focus {
        -webkit-box-shadow: none;
        border-bottom: 2px solid #003481;
      }
}

.btn-border {
    margin-top: 5px;
    width: 242px;
    border:none !important;
    height: 44px;
    background: #145EA8 !important;
    border-radius: 36px;
    &:hover {
        filter: brightness(90%);
    }
}
.btn-border-white {
    margin-top: 5px;
    width: 242px;
    height: 44px;
    color: #1976D2;
    font-weight: 600;
    border-radius: 36px;
    background-color: white;
    &:hover {
        background-color: #70b5f9;
        color: rgb(51, 51, 241);
    }
}

.btn-mini {
    padding: 1px;
}
.title_lable {
    color: var(--standart-theme-primary, #1976D2);
        font-feature-settings: 'liga' off;
        /* body-1/body-1, bold */
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 150% */
        letter-spacing: 0.08px;

}
.text_blue {
    font-weight: 600;
    color: #1976D2;

}

.text_italic {
    font-size: 12px;
    font-style: italic;

}

.text_btn_primary {
    color: #1976D2;
    cursor: pointer;
}

.lable-input {
    font-weight: 600;
  
}

.img-product-item {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin: 3px;
}
#demo-select-small {
    padding: 2px 5px;
    border: none;
    font-size: 14px;
}
.color-warning {
    background-color: #fdb762;
    border: none;
}
.color-warning2 {
    background-color: #FFEBB8;
    border: none;
}
.color-success {
    background-color: #36B37E;
}
.btn-select-status {
    border-radius: 18px !important;
    padding: 2px !important;
}
.item-3 {
    border-left: 3px solid gold !important;
}
.item-4 {
    border-left: 3px solid green !important;
}
.item-5 {
    border-left: 3px solid rgb(3, 100, 129) !important;
}

@media print {
    .modal-order-print {
        .div-image-logo {
            width: 200px;
            height: 200px;
            .image-logo-print {
                width: 200px;
                height: 200px;
            }
        }
        .tittle-header {
            font-size: 20px;
            font-weight: 600;
        }
        .text-bold-16 {
            font-size: 16;
            font-weight: 600;
        }
        .div-table {
            width: 96%;
            border-collapse: collapse;
        }
        .table {
            max-width: 790px;
        }
        .table ,th ,td {
            border: 1px solid black;
            border-collapse: collapse;
        }
                
        .td {
            padding-left: 3px
    
        }
        .tr {
            min-height: 32px !important
    
        }
    }
  
}
.react-datepicker-wrapper { 
    width: 100%;
}
.text-bold-14 {
    font-size: 14px;
    font-weight: 600;
}
.text-bold-16 {
    font-size: 16px;
    font-weight: 600;
}
.text-bold-12 {
    font-size: 12px;
    font-weight: 600;
}
.sz-16{
    font-size: 16px !important;
}
.sz-14{
    font-size: 14px;
}
.sz-12{
    font-size: 12px;
}
.sz-22{
    font-size: 22px !important;
}
.title-size-16 {
    font-size: 16px;
    font-weight: 600;

}
.border-25 {
    border-radius: 25px;
}

.div-card {
    padding: 12px;
    border-radius: 12px;
    border: 1px solid #cfcece;
    margin: 4px 0px;
    background-color: white;
}
.div-card2 {
    padding: 2px 4px;
    border-radius: 2px;
    border: 1px solid #e4e4e4;
    margin: 2px 0px;
    background-color: white;
}

.div-card-circle {
    padding: 6px 12px;
    border-radius: 35px;
    border: 1px solid #BDBDBD;
    background-color: #f4f4f4;
    margin: 4px 0px;
    text-align: center;
}

.div-card-white {
    padding: 12px;
    border-radius: 12px;
    margin: 4px 0px;
    background-color: white;
}


.item-option {
    padding: 5px !important;
    // background-color: red;
    cursor: pointer;
}
.list_chip {
    display: flex;
    flex-wrap: wrap;
    .item-chip {
        padding: 3px 4px;
        margin: 2px;
        background-color: rgb(199, 235, 241);
        border-radius: 12px;
        
        border: 1px solid #bdbdbd;
    }
}


.wd-20 {
    width: 20%;
}
.wd-60 {
    width: 620%;
}
.btn-add {
    width: max-content;
}
.text-user-info {
    // background-color: lightgray;
    border-radius: 10px;
    // padding: 10px;
    font-size: 16px;
    color: #1976D2;
    cursor: pointer;
}
.modal-custom-style {
    input::placeholder {
        color: #c6c6c6 !important;
    }
}

@media screen and (max-width: 1400px) {
    .btn-custom {
        height: 38px !important;
    }
    .table-fixed .text-content {
        font-size: 12px;
    }
    .card-service .form-control {
        font-size: 12px !important;
 
    }
    .size-mini-12 {
        font-size: 12px !important;
        padding: 2px;

    }
    .size-mini-14 {
        font-size: 14px !important;
        padding: 2px;

    }
  }

.div-plate {
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 4px;
    padding: 2px 4px;
    min-width: 100px;
    margin: auto;
    font-weight: 600;
    letter-spacing: 1.25px;
    padding: 4px 8px;
}
.div-plate-table {
    border-radius: 5px;
    border: 1px solid var(--opacity-text-disabled, rgba(0, 0, 0, 0.37));
    // padding: 2px 4px;
    min-width: 68px;
    font-weight: 600;
    text-align: center;
}

.number-danger {
    color: red;
    font-weight: 600;

}
.input-select-order {
    width: 280px !important;
}


.css-13xfq8m-MuiTabPanel-root {
    padding: 12px !important;
}
.css-1869usk-MuiFormControl-root {
    min-width: 100px !important;
}
.cont-customer {
    text-align: left;
    .text-name{
        color: #4b4b4b;
        font-size: 15px;
        font-weight: 600;
    }
}
.page_detail {
    padding: 24px;
}

.div-table-grid-tab {
    width: 100%;
    height: calc(100vh - 240px);
}

.div-table-grid {
    width: 100%;
    height: calc(100vh - 180px);
}

.div-receive {
    .item-button {
        border-radius: 5px;
        background: #FAFAFA;
        text-align: center;
        height: 80px;
        padding-top: 10px;
        cursor: pointer;
    }
    .active-item {
        border:1px solid #1976D2;
        color: #1976D2;
        background-color: rgba(181, 218, 255, 0.05);
    }
}

.div-filter {
    display: flex;
    justify-content: space-between;
    .input-group {
        max-width: 300px;
        margin: 0 5px;
        .input-no-border-1 {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }
        .icon-input {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    }
    .filter-select-date {
        border-radius: 20px;
        background-color: white;
        color: black;
        align-items: center; 
    }
    .filter-select {
        border-radius: 20px;
        background-color: white;
        align-items: center;
        max-width: 300px;
            color: black;
        .dropdown-menu.show {
            display: block;
            overflow-y: scroll;
            max-height: 300px;
        }

    }
    .div-date-search {
        // display: flex;
        padding: 3px 8px;

        .dropdown-menu {
        }
        .form-label {
            white-space: nowrap;
            margin-right: 3px;
            margin-top: 5px;
        }
        .react-datepicker-wrapper {
            margin: 0px 3px;
            // width: 100%;
        }
        .form-control {
            width: none;
        }
    }
    .dropdown-toggle {
        padding: 4px 18px;
        border-color: #E0E0E0;
    }
    
}

.div-infor-disable {
    border-radius: 5px;
    background-color: rgba(181, 218, 255, 0.05);
    color: black;
    border: 1px solid #1976D2;
    padding: 4px;
    .icon {
        width: 40px;
        height: 40px;
        text-align: center;
    }
    .title {
        font-weight: 600;
    }
}

.disable-item {
    color: grey;
}

.danger-item {
    color: red;
    
}
.btn-submit-order {
    width: 100%;
    padding: 16px;
}

@font-face {
    font-family: 'Roboto';
    src: url(../../public/static/fonts/Roboto-Regular.ttf) format('truetype');
}
//  search
body {
    font-family: 'Roboto', sans-serif !important;
}

.modal-image {
    max-height: 90vh;
    aspect-ratio: 1 / 1;
    max-width: 90vw;
}


.big-img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.all-img {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
}

.img-item {
    width: calc(33.33% - 8px);
    margin-bottom: 8px;
}

.small-img {
    width: 90px;
    object-fit: cover;
    aspect-ratio: 1 / 1;

    &.selected {
        border: 2px solid red;
    }
}

.icon-border {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 14px;
    border:none;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    flex-shrink: 0;
    border-radius: 12px;
    background: rgba(25, 118, 210, 0.05) !important;

    &:hover{
        filter: brightness(90%);
    }
}
.input-search-group {
    display: flex !important;
    width: 335px !important;
    padding: 8px 16px !important;
    align-items: center !important;
    gap: 4px !important;
    border-radius: 42px !important;
    border: 1px solid var(--grey-grey-lighten-2, #E0E0E0) !important;
    background: #FFF !important;

    .input-search {
        flex: 1 0 0 !important;
        font-family: Roboto !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 20px !important;
        letter-spacing: 0.25px !important;
        border: none !important;
        outline: none !important;
    }

}

.source-input{
    border-radius: 4px;
    border: 1px solid var(--grey-grey-lighten-3, #EEE);
    display: flex;
    width: 331px;
    align-items: center;
    gap: 4px;
}

.source-add-btn {
    border: none !important;
    height: 100% !important;
    width: 50px !important;
    background: var(--standart-theme-primary, #1976D2) !important;
    box-sizing: content-box !important;
    padding: 0 !important;
}


.big-btn {
    height: 66px !important;
    flex-shrink: 0 !important;
    border-radius: 10px ;
    background: var(--standart-theme-primary, #1976D2) !important;
    text-transform: uppercase !important;
    border: none !important;
    font-size: 14px !important;
}

.add-new-info {
    display: flex;
    // width: 331px;
    padding: 12px 4px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 4px !important;
    border-radius: 5px !important;
    background: #F3F8FD !important;
    color: #1976D2 !important;
    border: none !important;
}

.btn-custom {
    display: inline-flex !important;
    height: 44px;
    padding: 0px 16px;
        justify-content:center;
            align-items: center;
    gap: 16px;
    flex-shrink: 0;
    text-transform: uppercase ;
    border-radius: 4px !important;
    border: none !important;
    text-align: center;
    font-family: Roboto;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    background-color: #FFF;
    /* 257.143% */
    letter-spacing: 1.25px;
    text-decoration: none;
    &.save {
        min-width: 144px !important;
        justify-content: center !important; 
        background: var(--standart-theme-primary, #1976D2) !important;
        color: var(--shades-white, #FFF) !important;
    }

    &.add {
        justify-content: center;
        background: var(--standart-theme-primary, #1976D2);
        color: var(--shades-white, #FFF);
        min-width: 44px;
    }
    &.cancel {
        justify-content: center !important;
        align-items: center !important;
        background: #D8DADF !important;
        color: var(--shades-white, #000) !important;
    }
    
    &.confirm, &.lock {
        background: var(--standart-theme-error, #FF5252);
        color: var(--shades-white, #FFF);
    }

    &.unlock {
        background: var(--standart-theme-success, #4CAF50);
        color: var(--shades-white, #FFF);
    }

    &:hover {
    filter: brightness(80%)
    }

}

.d-hang {
    border: none !important;
    font-size: 12px !important;
    padding: 4px 12px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 4px !important;
    border-radius: 47px !important;
   
}
 .cho-duyet {
     background: #FB8C00 !important;
     color: var(--shades-white, #FFF) !important;

 }

 .hoan-thanh {
     background: var(--blue-blue-accent-4, #2962FF) !important;
     color: var(--shades-white, #FFF) !important;
 }

 .xuat-kho {
     background: #36B37E !important;
     color: var(--shades-white, #FFF) !important;
 }

 .dong-goi {
     background: #FFEBB8 !important;
     color: #000 !important;
 }

 .huy {
     background: var(--red-red, #F44336) !important;
     color: var(--shades-white, #FFF) !important;
 }

.ptn {
    min-width: 87px;
    font-size: 12px !important;
    padding: 4px 12px !important;
    gap: 4px !important;
    border-radius: 47px !important;
    color: white !important;
    font-weight: 400 !important;
    border-radius: 47px !important;
    color: white !important;
    border: none !important;
    font-style: normal;

// Trang thai phieu tiep nhan
    &.cho-duyet {
            background:#FB8C00 !important;
            color: var(--shades-white, #FFF) !important;
    
        }

    &.tiep-nhan {
        background: var(--light-blue-light-blue-lighten-2, #4FC3F7) !important;
        color: var(--shades-white, #FFF) !important;

    }

    &.bao-gia {
        background: var(--yellow-yellow-lighten-4, #FFF9C4) !important;
        color: var(--opacity-text--primary, rgba(0, 0, 0, 0.87)) !important;
    }

    &.sua-chua {
        background: var(--yellow-yellow-lighten-4, #F9A825) !important;
        color: var(--shades-white, #FFF) !important;
    }

    &.hoan-thanh {
        background: var(--blue-blue-accent-4, #2962FF) !important;
        color: var(--shades-white, #FFF) !important;
    }

    &.xuat-xuong {
        background: var(--light-green-light-green-darken-1, #7CB342) !important;
        color: var(--shades-white, #FFF) !important;
    }

    &.cancel {
        background: var(--red-red, #F44336) !important;
        color: var(--shades-white, #FFF) !important;
    }
}

.btn-status {
    text-decoration: none;
    font-size: 12px !important;
    padding: 4px 12px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 4px !important;
    border-radius: 47px !important;
    color: white !important;
    font-weight: 400 !important;
    border: none;
    font-style: normal;
    &.lock {
        background: var(--standart-theme-error, #FF5252) !important;
    }

    &.lich-hen,
    &.active {
        background: var(--standart-theme-success, #4CAF50) !important;
    }

    &.luu-dong {
        background: var(--light-blue-light-blue-lighten-2, #4FC3F7) !important;
    }
}


.btn-back {
    border-radius: 50px;
    width: max-content;
    background-color: transparent;
    flex-direction: row;
    padding: 5px;
    color: #1976D2 !important;
    border: 2px solid #1976D2 !important;

    &:hover {
        background-color: #1976D2 !important;
        color: #FFFFFF !important;
    }
}

.red {
    background-color: #FF5252 !important;
    color: #ffffff !important;
}

.orange {
    background-color: #ff7f00 !important;
    color: #ffffff !important;
}

.yellow {
    background-color: #f3f363 !important;
    color: #000000 !important;
}

.green {
    background-color: #4CAF50 !important;
    color: #ffffff !important;
}

.blue {
    background-color:#0B5ED7 !important;
    color: #ffffff !important;
}

.indigo {
    background-color: #4b0082 !important;
    color: #ffffff !important;
}

.violet {
    background-color: #9654b0 !important;
    color: #ffffff !important;
}

.pink {
    background-color: #ff69b4 !important;
    color: #000000 !important;
}

.black {
    background-color: #000000 !important;
    color: #ffffff !important;
}

.white {
    background-color: #ffffff !important;
    color: #000000 !important;
}

.gray {
    background-color: #808080 !important;
    color: #ffffff !important;
}

.brown {
    background-color: #a52a2a !important;
    color: #ffffff !important;
}

.red-text {
    color: #FF0000;
}

.blue-text {
    color: #0000FF;
}

.green-text {
    color: #00FF00;
}

.yellow-text {
    color: #FFFF00;
}

.white-text {
    color: #FFFFFF;
}

.black-text {
    color: #000000;
}

.gray-text {
    color: #808080;
}

.orange-text {
    color: #FFA500;
}

.pink-text {
    color: #FF69B4;
}

.purple-text {
    color: #800080;
}

.brown-text {
    color: #A52A2A;
}

.navy-text {
    color: #000080;
}



// Typography helper classes
h1 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 96px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 96px !important;
    letter-spacing: -1.44px !important;
}

h2 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 60px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 60px !important;
    letter-spacing: -0.3px !important;
}

h3 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 48px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 50px !important;
}

h4 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 34px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 40px !important;
    letter-spacing: 0.085px !important;
}

h5 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 32px !important;

    &.medium {
    font-weight: 600 !important;
    }
}

h6 {
    color: rgba(0, 0, 0, 0.87) ;
    font-family: Roboto !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 32px !important;
    letter-spacing: 0.05px !important;
}

.subtitle-1 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    letter-spacing: 0.024px !important;
}

.subtitle-2 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    letter-spacing: 0.014px !important;
}

.body-1 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-feature-settings: 'liga' off !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0.08px !important;
}

.body-2 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0.035px !important;
}

.button {
    color: rgba(0, 0, 0, 0.60) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

.caption {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0.048px !important;
}

.overline {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 32px !important;
    letter-spacing: 0.24px !important;
    text-transform: uppercase !important;
}

.font-italic {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: italic !important;
    font-weight: 400 !important;
    line-height: 24px !important;
}

.thin {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 100 !important;
    line-height: 24px !important;
}

.light {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 24px !important;
}

.regular {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
}

.medium {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
}

.bold {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 24px !important;
}

.black {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 900 !important;
    line-height: 24px !important;
}

.text--primary {
    color: var(--opacity-text--primary, rgba(0, 0, 0, 0.87)) !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

.text--secondary {
    color: var(--opacity-text--secondary, rgba(0, 0, 0, 0.60)) !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

.text--disabled {
    color: var(--opacity-text--disabled, rgba(0, 0, 0, 0.37)) !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}
.css-19kzrtu {
    padding: 4px !important;
}