.page-navigation {
    ul {
        margin: 0;
    }

    .page-link {
        border: 0;

        &:focus {
            box-shadow: none;
            outline: 0;
        }

        &:hover {
            color: #fff;
            background-color: #007bff;
            border: 0;
            border-radius: 4px;
        }
    }

    .page-item.active .page-link {
        border-radius: 4px;
    }


}